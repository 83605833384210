@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  margin: 0;
  padding: 0;
  outline: 0;
  box-sizing: border-box;
}
.z-top {
  z-index: 99999;
}

/* canvas {
  width: 100% !important;
  height: 100vh !important;
} */
